.portfolio{
    padding-top: 200px;
}

.contain-galerie{
    padding: 100px;
 
  
   
    background-image: url(../../images/tuile.jpg);
    background-repeat: no-repeat;
    background-size: cover;
   
}

.galerie{
    font-size:50px;
    font-family: sans-serif;
    font-style: oblique;
    text-align: center; 
    color:rgb(255, 255, 255);
    
}