.section-container {
    padding-top: 90px;
    padding-bottom: 60px;
    background-color: #ffffff;
}

.img-service:hover{

opacity: 0.5;
cursor: pointer;
}

@media (min-width: 992px){
.container {
    padding-left: 16px;
    padding-right: 16px;
}
section, .container, .container-fluid {
    position: relative;
    word-wrap: break-word;
}}

@media (min-width: 1200px){
 .container-lg, .container-md, .container-sm {
    max-width: 1140px;
}}
.section-container .item-wrapper {
    padding-bottom: 2rem;
}



.section-container .features-image .img-service {
    width: 100%;
    z-index: 1;
}
.img-service, iframe {
    display: block;
    width: 100%;
}

.img-service {
    vertical-align: middle;
    border-style: none;
}
.img-service {
    vertical-align: middle;
    border-style: none;
}
*, ::after, ::before {
    box-sizing: border-box;
}
*, ::after, ::before {
    box-sizing: inherit;
}
*, ::after, ::before {
    box-sizing: border-box;
}
feuille de style user-agent
.img-service {
    overflow-clip-margin: content-box;
    overflow: clip;
}
.align-left {
    text-align: left;
}
section, .container, .container-fluid {
    position: relative;
    word-wrap: break-word;
}

.section-container .features-image .card {
    margin-left: 20%;
    background-color: #ffffff;
    padding: 2rem;
    position: relative;
    margin-top: -8rem;
    right: 1rem;
    z-index: 2;
    border-radius: 0;
    background-color: #121211a6;
}
.card {
    background-color: transparent;
    border: none;
}
.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;}

    .section-container .card .mbr-text, .section-container .card .mbr-section-title {
        margin-bottom: 10px;
    }
    .display-5 {
        font-family: 'Kumbh Sans', sans-serif;
        font-size: 1.7rem;
        line-height: 1.5;
    }
    .mbr-semibold {
        font-weight: 600;
    }
    .mbr-semibold {
        font-weight: 500;
    }
    .mbr-black {
        color: #111;
    }
    .mbr-section-title {
        font-style: normal;
        line-height: 1.1;
        color: #fff;
    }

    .section-container .card .mbr-text, .section-container .card .mbr-section-title {
    margin-bottom: 10px;
    color: #fff;
}
.display-4 {
    font-family: 'Kumbh Sans', sans-serif;
    font-size: 1.25rem;
}
.mbr-regular {
    font-weight: 400;
}
.mbr-regular {
    font-weight: 400;
}
.mbr-black {
    color: #f8f0f0df;
   
}
.mbr-text {
    font-style: normal;
    line-height: 1.7;
}

.mbr-section-title{
    text-decoration:underline;
    
}

.section-big-title{
    text-decoration: none;
   background-color: #111111de;
   color: #f8f0f0df;
   
    
}