.marketing .col-lg-4 {
    margin-bottom: 1.5rem;
    text-align: center;
}

.Titre{
    text-align: center;
    margin-bottom: 20px;
}

.titre-service{
    text-align: center;
    margin-bottom: 20px;
}


/* .active.fade-bottom{
    
    animation: fade-bottom 1s ease-in;
  
} */

.rounded-circle{
  object-fit: cover;
}

/* @keyframes fade-bottom {
0% {
  transform: translateY(50px);
  opacity: 0;
}
100% {
  transform: translateY(0);
  opacity: 1;
}
} */



