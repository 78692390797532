.card-container {
    margin: 20px 30px 20px 90px;
    flex-direction: column;
}


.img-animation {
   
    margin: 1em;
	-webkit-transform: scale(1);
	transform: scale(0.9);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
  
}
.img-animation:hover  {
	-webkit-transform: scale(1.3);
	transform: scale(1.1);
}

/**Masquer le bouton */
/* #btn-galerie{
    display: none;
} */
.txt-titre{
    
position:absolute;
    color: #2d3126;
    margin: 0.20em 0.75em 0 0.2em;
    font-family: 'Bungee Shade', cursive;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
    text-orientation: upright;
    -webkit-font-feature-settings: "vkrn", "vpal";
    font-feature-settings: "vkrn", "vpal";

}
/* Bouton  */
.for_topic__question__more {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 45px;
    z-index: 1;
    padding-bottom: 5px;
}

*, :after, :before {
    box-sizing: border-box;
}
[class*=ico--] {
    display: inline-block;
    vertical-align: middle;
}
.btn--border {
    border-radius: 20px;
    font-size: .875rem;
    height: 40px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.2);
    display: inline-block;
    font-weight: 700;
    text-decoration: none!important;
    line-height: 2.375rem;
    border: none;
    padding: 0 20px;
    background: #fff;
    position: relative;
    z-index: 1;
    margin:5px;
}

[class*=ico--]:before {
    content: "";
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: sub;
    background-repeat: no-repeat;
}
[class*=ico--].btn:before, [class*=ico--][class*=btn--]:before {
    margin-right: 5px;
   
}
.ico--chevron-down:before, .ico--chevron-down:focus:before {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M.32 7.68l9.11 8.1A.86.86 0 0010 16a.853.853 0 00.57-.22l9.11-8.1a.993.993 0 00.113-1.338.851.851 0 00-1.253-.122L10 13.814 1.46 6.22a.853.853 0 00-1.253.122A.993.993 0 00.32 7.68z' fill='%23303030'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h20v20H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.ico--chevron-down:hover::before{
    transition:transform .5s ease-in;
    transition:all .3s ease-out; transform: rotate(360deg);
}

@media only screen and (max-width:750px){
    #img-mobile{
        display: none;
    }

    #btn-galerie{
        margin-top: 10px;
        display: block;
    }
}
@media only screen and (max-width: 1200px){
    .sec-colonne{
          display: none;
    }

    .txt-titre{
        font-size: 17px;
        margin-left: 40px;
    }
  
    }

/* .frame {
	text-align: center;	
	position: relative;
	cursor: pointer;	
	perspective: 500px; 
}
.frame img {
	width: 300px;
	height: 300px;
}
.frame .details {
	width: 40%;
	height: 90%;	
	padding: 5% 8%;
	position: absolute;
	content: "";
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotateY(90deg);
	transform-origin: 50%;
	background: rgba(255,255,255,0.9);	
	opacity: 0;
	transition: all 0.4s ease-in;
	
}
.frame:hover .details {
	transform: translate(-50%, -50%) rotateY(0deg);
	opacity: 1;
}

.row {
	margin-top: 12px;
}

.column {
	display: inline-block;
	text-align: center;
} */