/* .active.fade-bottom{
    
    animation: fade-bottom 1s ease-in;
  
}

@keyframes fade-bottom {
0% {
  transform: translateY(50px);
  opacity: 0;
}
100% {
  transform: translateY(0);
  opacity: 1;
}
} */

.container1{
  margin-bottom: 20px;

    margin-top: 50px;


}


.featurette-heading1{
  
    background-color: #000000cf;
    color: white;

}
/*Partie animation du texte*/
/* .txt-animation{
  display:inline-block;
  overflow:hidden;
  white-space:nowrap;
} */

/* .txt-animation:first-of-type {    /* For increasing performance 
                       ID/Class should've been used. 
                       For a small demo 
                       it's okaish for now 
  animation: showup 7s infinite;
}

.txt-animation:last-of-type {
  width:0px;
  animation: reveal 7s infinite;
}

.txt-animation:last-of-type span {
  margin-left:-355px;
  animation: slidein 7s infinite;
} */

@keyframes showup {
    0% {opacity:0;}
    20% {opacity:1;}
    80% {opacity:1;}
    100% {opacity:1;}
}

@keyframes slidein {
    0% { margin-left:-800px; }
    20% { margin-left:-800px; }
    35% { margin-left:0px; }
    100% { margin-left:0px; }
}

@keyframes reveal {
    0% {opacity:0;width:0px;}
    20% {opacity:1;width:0px;}
    30% {width:355px;}
    80% {opacity:1;}
    100% {opacity:1;width:355px;}
}



/* .active.fade-left {
    animation: fade-left 1s ease-in;
  }

  @keyframes fade-left {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .active.fade-right {
    animation: fade-right 1s ease-in;
  }
  @keyframes fade-right {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  } */