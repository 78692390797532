
/* #hero {
    width: 100%;
  
    height: 70vh;
   
    background: url("../images/multi.JPG") ;
    background-size: cover;
    position: relative;
    background-attachment: fixed; 
}

#hero .hero-container {
    position: absolute; 
    background-color: #00000063;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 15px;
}

#hero h1 {
    margin: 0 0 10px 0;
    font-size: 64px;
    font-family: "Satisfy", serif;
    color: #fff;
}
#hero h2 {
    color: #eee;
    margin-bottom: 50px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}
#hero .btn-scroll {
    transition: 0.4s;
    color: rgba(255, 255, 255, 0.6);
    animation: up-down 1s ease-in-out infinite alternate-reverse both;
}

#hero .btn-scroll:hover {
    color: #ffb727;
}

.bx {
    font-family: boxicons!important;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
    display: inline-block;
    text-transform: none;
    speak-as: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#hero .btn-scroll i {
    font-size: 48px;
}

@media only screen and (max-width: 1100px){
   #hero {
      height: 50vh;
   }
}

@media only screen and (max-width:650px) {
    #hero h1 {
   
        font-size: 30px;
       
    }
    #hero h2 {
       
        font-size: 13px;
     
    }
}

.txt-animation{
    display:inline-block;
    overflow:hidden;
    white-space:nowrap;
  }
  
  .txt-animation:first-of-type {    
    animation: showup 7s infinite;
  }
  
  .txt-animation:last-of-type {
    width:0px;
    animation: reveal 7s infinite;
  }
  
  .txt-animation:last-of-type span {
    margin-left:-355px;
    animation: slidein 7s infinite;
  }
  
  @keyframes showup {
      0% {opacity:0;}
      20% {opacity:1;}
      80% {opacity:1;}
      100% {opacity:1;}
  }
  
  @keyframes slidein {
      0% { margin-left:-800px; }
      20% { margin-left:-800px; }
      35% { margin-left:0px; }
      100% { margin-left:0px; }
  }
  
  @keyframes reveal {
      0% {opacity:0;width:0px;}
      20% {opacity:1;width:0px;}
      30% {width:355px;}
      80% {opacity:1;}
      100% {opacity:1;width:355px;}
  } */
  

  /*Paralax effect*/
   .header-scroll {
    --color-background-rgb: 0, 0, 0;
    --color-default: #ffffff;
     height: 30em; 
    padding: 180px 0;
    position: relative;
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
    width: 100%;
  
    height: 70vh;
}.header-scroll:before {
    content: "";
    background: rgba(var(--color-background-rgb), 0.5);
    position: absolute;
    inset: 0;
    z-index: 2;
}.header-scroll .container-citation {
    position: relative;
    z-index: 3;
}
html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
}
[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
    transition-delay: .1s;
}html:not(.no-js) [data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    transition-property: opacity,-webkit-transform;
    transition-property: opacity,transform;
    transition-property: opacity,transform,-webkit-transform;
}.text-center-header {
    text-align: center!important;
}

.container-citation {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}.header-scroll img {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 1;
}
.txt-creatif-header{
   
    font-size: 80px;
    font-family: "Satisfy", serif;
    color: #fff;
}
.txt-crea-header{
    color: #eee;
  
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}

/* @media only screen and (max-width: 1040px){
.header-scroll {
  
    padding: 150px 0;
   
}
.txt-creatif-header{
    font-size: 50px;
}} */

/* @media only screen and (min-width: 750px){
    .header-scroll {
      
        padding: 150px 0;
       
    }
    .txt-creatif-header{
        font-size: 50px;
    }} */
    @media only screen and (max-width: 850px){
        .header-scroll {
          
            padding: 150px 0;
           display: flex;
           justify-content: center;
           align-items: center;
        }
        .txt-creatif-header{
            font-size: 50px;
        }}