.contact-container {
    
    width: 100vw;
    height: 100vh;
    background: var(--bg-color);
   
   
  }
  .maps-img-contact{
    height: 600px;
    
    display: flex;
    justify-content: center;
  }
  .left-col {
    width: 100%;
    height: 50%;
    background-image: url("../../images/outil.jpg" );
    background-size: cover;
    background-repeat: no-repeat;
   
  
    transition: all 0.3s ease-in-out;
  }
  

  .right-col {
    background: var(--bg-color);
    /* width: 50vw;
    height: 100vh; */
    padding: 5rem 3.5rem;
    /* background-color: rgba(255, 255, 250, 0.64); */
  }

  ion-icon:not([name="logo-codepen"]) {
    border: 1px solid currentColor;
    border-radius: 20%;
    padding: 1rem;
  }

  .Lien-contact{
    display: flex;
  }
.social-link-contact{
  display: flex;
  justify-content: space-around;
}
  @media only screen and (max-width: 450px){
    .left-col {
      width: 100%;
      height: 30%;
    }}