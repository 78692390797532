.card-container{
    padding: 20px 25px 25px 30px;
    display: flex;
    
}

.for_topic__question__more {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 45px;
    z-index: 1;
    padding-bottom: 5px;
}

*, :after, :before {
    box-sizing: border-box;
}
[class*=ico--] {
    display: inline-block;
    vertical-align: middle;
}

.serv-txt{
    font-size: 20px;
    font-weight: 600;
    
}
.btn--border-service {
    border-radius: 20px;
    font-size: .875rem;
    height: 40px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.2);
    display: inline-block;
    font-weight: 700;
    text-decoration: none!important;
    line-height: 2.375rem;
    border: none;
    padding: 0 20px;
    margin-top: 30px;
    background: #fff;
    position: relative;
    z-index: 1;
}
/* Bouton configuration */
[class*=ico--]:before {
    content: "";
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: sub;
    background-repeat: no-repeat;
}
[class*=ico--].btn:before, [class*=ico--][class*=btn--]:before {
    margin-right: 5px;
   
}
/* .ico--chevron-down:before, .ico--chevron-down:focus:before {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M.32 7.68l9.11 8.1A.86.86 0 0010 16a.853.853 0 00.57-.22l9.11-8.1a.993.993 0 00.113-1.338.851.851 0 00-1.253-.122L10 13.814 1.46 6.22a.853.853 0 00-1.253.122A.993.993 0 00.32 7.68z' fill='%23303030'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h20v20H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.ico--chevron-down:hover::before{
    transition:transform .5s ease-in;
    transition:all .3s ease-out; transform: rotate(360deg);
} */


/* @media only screen and (max-width:750px) {
    .card-container{
         padding: 20px 25px 25px 30px;
    display: flex;
    flex-direction: column;
    }
   

} */