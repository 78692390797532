/* .img-desc{
    width: 450px;
    height: 700px;
    object-fit: cover;
} */

/* .container-desc{
    margin: 20px 20px 10px 20px;
    
} */

.txt-mid{
    background-color: #F8F9FA;
}

.img-left{
    background-color: #F8F9FA;
}

.tiret{   /*Barre lateral noir*/
    border-right: 5px solid #222;
    padding-right: 60px;
}


@media only screen and (max-width: 1000px){
    .desc-contain {
   display: flex;
   flex-direction: column;
 
    }
.lead{
    padding: 5px;
}

.heading-desc{
    padding: 5px;
   
}
.txt-desc{
    margin: 10px;
}


}
