.Lien{
    list-style: none;
    margin-bottom: 30px;
}
.img-maps{
   height: 200px;
   width: 300px;
  
}
a{
    color: black;
}.back-to-top.active {
    visibility: visible;
    opacity: 1;
}
.icon {
    font-size: 28px;
    color: #fff;
    line-height: 0;
}
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    
    width: 40px;
    height: 40px;
    border-radius: 50px;
    transition: all 0.4s;
    color: black
}

.droit{
    margin-top: 20px;
}

.link-social{
    margin-top: 15px;
}
@media only screen and (max-width: 800px){
    .link-social{
          display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    }
  
    }
    @media only screen and (max-width: 900px){
     
      
        
    .img-maps{
        height: 200px;
        width: 300px;
       
     }}