.call-to-action {
    --color-default: #ffffff;
    /* height: 30em; */
    --color-background-rgb: 0, 0, 0;
    padding: 80px 0;
    position: relative;
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
}.call-to-action:before {
    content: "";
    background: rgba(var(--color-background-rgb), 0.5);
    position: absolute;
    inset: 0;
    z-index: 2;
}.call-to-action .container-citation {
    position: relative;
    z-index: 3;
}
html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
}
[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
    transition-delay: .1s;
}html:not(.no-js) [data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    transition-property: opacity,-webkit-transform;
    transition-property: opacity,transform;
    transition-property: opacity,transform,-webkit-transform;
}.text-center {
    text-align: center!important;
}

.container-citation {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}.call-to-action img {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 1;
}
.txt-creatif{
    color: #ffffff;
    font-family: 'Pacifico', cursive;
    font-size: 5em;
}
.txt-crea{
    color: #ffffff;
    font-family: 'Kalam', cursive;
    font-size: 3em;
}